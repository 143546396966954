import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './Start.css';
// import { fetchSessionId, upsertSessionId, upsertChatHistory } from '../../api/ApiService';
import SessionInfo from '../../model/SessionInfo'; 
import ChatHistory from '../../model/ChatHistory';
// const initializeChatHistory = (sessionId) => {
//   try{
//     let chatHistoryUuid = localStorage.getItem('chatHistoryUuid');
//     if (!chatHistoryUuid) {
//       chatHistoryUuid = uuidv4();
//       localStorage.setItem('chatHistoryUuid', chatHistoryUuid);
//     }
//     upsertChatHistory(sessionId)
//     .then(response => {
//       console.log("Chat history upserted:", response.data);
//     })
//     .catch(error => {
//       console.error("Error upserting chat history:", error);
//     });
//     const chatHistory = new ChatHistory(sessionId, chatHistoryUuid);
//     // You can now use chatHistory as needed  
//     console.log(chatHistory);
//   }catch(e){
//     console.log("test e1");
//   }
// };
const Start = () => {
  const divideSpeed = 2;
  const [fadeStage, setFadeStage] = useState('fade-in');
  const [showLogo, setShowLogo] = useState(true);
  const [showWelcome, setShowWelcome] = useState(false);
  const [showSwipeUp, setShowSwipeUp] = useState(false);
  const [sunPosition, setSunPosition] = useState(0);
  const [isFadingOut, setIsFadingOut] = useState(false);

  const navigate = useNavigate();
  const handleClick = () => {
    setIsFadingOut(true);

    setTimeout(() => {
      navigate('/testchat');
    }, 1000);
  };

  useEffect(() => {
    const sessionInfo = new SessionInfo(); // Create a new SessionInfo instance

    // Fetch session ID  
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId) {
      // If sessionId doesn't exist, request a new one from the server  
      // fetchSessionId()
      //   .then(response => {
      //     sessionId = response.data.session_id;
      //     localStorage.setItem('sessionId', sessionId);
      //     sessionInfo.sessionId = sessionId; // Update the sessionId in SessionInfo  
      //     // upsertSessionId(sessionId);
      //   });
    } else {
      // If sessionId exists, ensure it's upserted on the server  
      sessionInfo.sessionId = sessionId;
      // upsertSessionId(sessionId);
    }
    // initializeChatHistory(sessionInfo.sessionId);
    const handleSwipeOrScroll = (e) => {
      if (sunPosition === null) {
        // Set initial position when a swipe or scroll is detected  
        setSunPosition(0);
      }

      // Calculate the new position based on scroll or swipe  
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      const docHeight = document.documentElement.scrollHeight - window.innerHeight;
      const scrollPosition = (scrollTop / docHeight) * 100;
      // Update the sun's position  
      setSunPosition(scrollPosition);
      setShowSwipeUp(false);
      if(sunPosition === 0) setShowSwipeUp(true);
    };

    // Add event listeners for both scroll and touchmove (for swipe detection)
    window.addEventListener('scroll', handleSwipeOrScroll);
    window.addEventListener('touchmove', handleSwipeOrScroll);


    const fadeInTimer = setTimeout(() => {
      setFadeStage('visible');
    }, 1000 / divideSpeed); // 1 second for logo fade-in

    const fadeOutTimer = setTimeout(() => {
      setShowLogo(false);
      setShowWelcome(true);
    }, 2000 / divideSpeed);

    const swipeUpTimer = setTimeout(() => {
      setShowSwipeUp(true);
    }, 4000 / divideSpeed);


    

    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(swipeUpTimer);
      window.removeEventListener('scroll', handleSwipeOrScroll);
      window.removeEventListener('touchmove', handleSwipeOrScroll);

    };
  }, [sunPosition]);

  return (
    <>
      <div className={`container ${fadeStage}`}>
        {showLogo && (
          <div className="logo-container">
            <img  
              src={`${process.env.PUBLIC_URL}/healthChat.jpg`}
              alt="HealthChat Logo"
              className="logo"
            />
            <div className="stardust">
              <img src={`${process.env.PUBLIC_URL}/thumbnail/stardust.svg`} alt="Stardust" />
            </div>
            <div className="logo-text">healthChat</div>          
          </div>
        )}
        {!showLogo && (
          <div className="nature-fade-in background-svg">
            <img src={`${process.env.PUBLIC_URL}/start/01-welcome-nature.svg`} alt="Welcome Nature" />
          </div>
        )}
        {showWelcome && (
          <div className="welcome-text">
            <img src={`${process.env.PUBLIC_URL}/start/01.1-Welcome.svg`} alt="Welcome to healthChat" />
          </div>
        )}
        {showSwipeUp && (
          <>
            <div className="swipe-up-animation">  
              <img src={`${process.env.PUBLIC_URL}/start/01.3-swipe-text.svg`} alt="Swipe Up ☝️" />
              {/* <img src={`${process.env.PUBLIC_URL}/start/01.2-swipe-up.svg`} alt="Swipe Up" /> */}
            </div>
          </>

        )}

        {!showLogo && sunPosition !== null && (
            <div  
              className="sun"
              style={{
                transform: `translate(${sunPosition}%, ${sunPosition}%)`,
              }}
            >
              <img src={`${process.env.PUBLIC_URL}/start/02.0-sun.svg`} alt="Sun" />
            </div>
        )}
      </div>
      {(
        <>
          <div className="second-container">
            <span></span>
            <img className='myname' src={`${process.env.PUBLIC_URL}/start/02.1-myname.jpg`}/> 
            <img src={`${process.env.PUBLIC_URL}/start/02.2-i-am-healthchat.svg`} alt="i am healtch chat" />
            <img className='myname' src={`${process.env.PUBLIC_URL}/start/03.1-healthy-eating.jpg`}/>
            <img src={`${process.env.PUBLIC_URL}/start/03.2-healthchat-full.svg`} alt="Healthy Eating And Lifestyle Transformation Hub" />
            <img className='myname' src={`${process.env.PUBLIC_URL}/start/03.3-bot-exercise.jpg`}/>
            <img className='myname' src={`${process.env.PUBLIC_URL}/start/03.4-fat-loss.svg`}/>
            <button  
                onClick={handleClick}
                className={`px-6 py-3 font-bold text-white rounded-lg shadow-md glowing-button shadow-blue-500/15 transition-opacity duration-1000 ${
                  isFadingOut ? 'opacity-0' : 'opacity-100'
                }  glowing-button  mt-5 mx-auto block`}
              >
                Get Started Now
            </button>
            <img className='myname' src={`${process.env.PUBLIC_URL}/thumbnail/svg256.svg`}/>
            {/* <img className='myname' src={`${process.env.PUBLIC_URL}/thumbnail/svg256.svg`}/> */}

          </div>
        </>
      )}
    </>
  );
};

export default Start;