
import { configureStore, createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentPage: '/',
  user: {
    email: null,
    name: null,
  },
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setUserInfo: (state, action) => {
      state.user = action.payload;
    },
    clearStore: () => initialState,
  },
});

// Export actions  
export const { setCurrentPage, setUserInfo, clearStore } = appSlice.actions;

// Create store  
const Store = configureStore({
  reducer: {
    app: appSlice.reducer,
  },
});

export default Store;