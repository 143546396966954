import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  MinChatUiProvider,
  MainContainer,
  MessageInput,
  MessageContainer,
  MessageList,
  MessageHeader  
} from "@minchat/react-chat-ui";

import { formatTime, getArrayByStateOfIntroduction } from '../../util/Util'; // Import the formatTime function
// import { upsertChatRoom } from '../../api/ApiService';

const TestChat = ({ setIsAllowedBackHome }) => {
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showSuggestedReply, setShowSuggestedReply] = useState(false);
  const [suggestedReplyText, setSuggestedReplyText] = useState('Tell me more');
  const navigate = useNavigate();

  const sessionId = localStorage.getItem('sessionId');
  const roomId = sessionId; // You can generate a unique ID or use a predefined one  
  const roomTitle = '';
  useEffect(() => {
    // upsertChatRoom(sessionId, roomId, roomTitle)
    // .then(response => {
    //   console.log(response.data.message);
    // })
    // .catch(error => {
    //   console.error('Error upserting chat room:', error);
    // });

    let savedState = localStorage.getItem('stateOfIntroduction');
    if (!savedState) {
      localStorage.setItem('stateOfIntroduction', '0');
      savedState = '0';
    }
    if(savedState === '0'){
       setIsTyping(true);
    }

    setIsAllowedBackHome(false);

    // Simulate initial typing for 2 seconds  
    const typingTimeout = setTimeout(() => {

      setIsTyping(false);
      if(savedState === '0') savedState = '10';
      const initialMessages = [getArrayByStateOfIntroduction('10')];
      setMessages(initialMessages);
      if(savedState === '10') setShowSuggestedReply(true);
      else setShowSuggestedReply(false);
      localStorage.setItem('stateOfIntroduction', savedState);
      if(savedState >= '20'){
        setSuggestedReplyText('Tell me more');
        handleSuggestedReply(false); 
      }

    }, (savedState === '0' ? 2000 : 0));


    return () => clearTimeout(typingTimeout);
  }, [setIsAllowedBackHome]);

  const handleSuggestedReply = (isTypingAllowed = true) => {
    if (suggestedReplyText === 'Tell me more') {
      let savedState = '20';
      localStorage.setItem('stateOfIntroduction', savedState)
      setMessages(prevMessages => [ ...prevMessages,
        getArrayByStateOfIntroduction(savedState + 'a') ]);

      setShowSuggestedReply(false); // Hide the button after clicking  
      if(isTypingAllowed)setIsTyping(true); // Show typing indicator again

      setTimeout(() => {
        setIsTyping(false);
        setMessages(prevMessages => [
          ...prevMessages,
          getArrayByStateOfIntroduction(savedState + 'b') ]);

        // Add healthChat's full introduction after a 1-second delay  
        setTimeout(() => {
          setMessages(prevMessages => [
            ...prevMessages,
            getArrayByStateOfIntroduction(savedState + 'c') ]);

          // Add survey invitation message after another 1-second delay  
          setTimeout(() => {
            setMessages(prevMessages => [
              ...prevMessages,
              getArrayByStateOfIntroduction(savedState + 'd') ]);
            setShowSuggestedReply(true);
            setSuggestedReplyText('Continue with Survey');
          }, (isTypingAllowed ? 1000 : 0));
        }, (isTypingAllowed ? 1000 : 0));
        
      }, (isTypingAllowed ? 2000 : 0));
    } else if (suggestedReplyText === 'Continue with Survey') {
      navigate('/survey');
    }
  };


  return (
    <>
      <MinChatUiProvider theme="#6ea9d7">
        <MainContainer style={{ height: '100vh', width: '100vw' }}>
          <MessageContainer>
            <MessageHeader />
            <MessageList  
              currentUserId='user01'
              messages={messages.map(msg => ({
                ...msg,
                user: {
                  ...msg.user,
                  name: `${msg.user?.name ?? ''} (${formatTime(msg.timestamp)})`
                }
              }))}
            />
            {isTyping && (
              <div style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
                <span style={{ fontSize: '0.75em', marginRight: '5px', color: '#555' }}>
                  healthChat is typing  
                </span>
                <img  
                  src="chat/gif-typing.gif"
                  alt="Typing indicator"
                  style={{ width: '40px', height: 'auto' }}
                />
              </div>
            )}
            {showSuggestedReply && (
              <div style={{ padding: '10px', textAlign: 'center' }}>
                <button  
                  onClick={handleSuggestedReply}
                  style={{
                    padding: '5px 10px',
                    backgroundColor: '#6ea9d7',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer'
                  }}
                >
                  {suggestedReplyText}
                </button>
              </div>
            )}
            <MessageInput placeholder="Login & Talk to healthChat AI" />
          </MessageContainer>
        </MainContainer>
      </MinChatUiProvider>
    </>
  );
};

export default TestChat;