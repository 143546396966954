import React, { useState, useEffect } from 'react';
import Ripples from 'react-ripples';
import { v4 as uuidv4 } from 'uuid';
import Typewriter from 'typewriter-effect';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setUserInfo } from '../store/Store';
// import { fetchSessionId, upsertSessionId, upsertChatHistory } from '../api/ApiService'; // Import the fetchSessionId function  

// import FloatingImage from './start/FloatingImage';
import FloatingPanel from './start/FloatingPanel';
import ChatHistory from '../model/ChatHistory';
import SessionInfo from '../model/SessionInfo'; // Import SessionInfo  
import '../input.css';
import './Home.css';

const Home = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
    },
    logo: {
      width: '100px',
      height: 'auto',
    },
  };

  const [showTypewriter, setShowTypewriter] = useState(false);
  const [loggedInName, setLoggedInName] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isFadingOut, setIsFadingOut] = useState(false);
  const [isVisited, setIsVisited] = useState(false);

  const currentPage = useSelector((state) => state.app.currentPage);

  useEffect(() => {
    const sessionInfo = new SessionInfo(); // Create a new SessionInfo instance


    const visited = localStorage.getItem('isVisited');

    if (visited == '1') {
      debugger;
      setIsVisited(true);
      navigate('/'); // Navigate to Home  
      localStorage.setItem('isVisited', 1);
    } else {
      debugger;
      setIsVisited(false);
      navigate('/start'); // Navigate to Start  
    }
    // Fetch session ID  
    let sessionId = localStorage.getItem('sessionId');

    if (!sessionId) {
      // If sessionId doesn't exist, request a new one from the server  
      // fetchSessionId()
      //   .then(response => {
      //     sessionId = response.data.session_id;
      //     localStorage.setItem('sessionId', sessionId);
      //     sessionInfo.sessionId = sessionId; // Update the sessionId in SessionInfo  
      //     // upsertSessionId(sessionId);
      //   });
    } else {
      // If sessionId exists, ensure it's upserted on the server  
      sessionInfo.sessionId = sessionId;
      // upsertSessionId(sessionId);
    }
    // initializeChatHistory(sessionInfo.sessionId);

    if (currentPage === '/survey') {
      navigate('/survey');
    }

    const getQueryParams = (param) => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    };

    const email = getQueryParams('email');
    const name = getQueryParams('name');

    if (email) {
      localStorage.setItem('loggedInEmail', email);
      dispatch(setUserInfo({ email, name }));
    }
    if (name) {
      localStorage.setItem('loggedInName', name);
    }
    const storedName = localStorage.getItem('loggedInName');
    if (storedName) {
      setLoggedInName(storedName);
    }

    const timer = setTimeout(() => {
      setShowTypewriter(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, [currentPage, navigate, dispatch]);

  // const initializeChatHistory = (sessionId) => {
  //   debugger;
  //   try{
  //     let chatHistoryUuid = localStorage.getItem('chatHistoryUuid');
  //     if (!chatHistoryUuid) {
  //       chatHistoryUuid = uuidv4();
  //       localStorage.setItem('chatHistoryUuid', chatHistoryUuid);
  //     }
  //     upsertChatHistory(sessionId)
  //     .then(response => {
  //       console.log("Chat history upserted:", response.data);
  //     })
  //     .catch(error => {
  //       console.error("Error upserting chat history:", error);
  //     });
  //     const chatHistory = new ChatHistory(sessionId, chatHistoryUuid);
  //     // You can now use chatHistory as needed  
  //     console.log(chatHistory);
  //   }catch(e){
  //     console.log("test e1");
  //   }
  // };
  const handleClick = () => {
    setIsFadingOut(true);

    setTimeout(() => {
      navigate('/testchat');
    }, 1000);
  };

  return (
    <>
    <div className="flex flex-col  h-screen">
      <div className="text-xl font-thin text-center text-blue-600 mb-4">
        <div style={styles.typewriter}>
          {loggedInName ? (
            <Typewriter  
              options={{
                strings: [`Welcome, ${loggedInName}`],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                pauseFor: 99999,
                cursor: '',
              }}
            />
          ) : (
            <Typewriter  
              options={{
                strings: ['Welcome to healthChat'],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                pauseFor: 99999,
                cursor: '',
              }}
            />
          )}
        </div>
      </div>

      <div className="text-l font-thin text-center text-blue-600 mb-4">
        <div style={styles.typewriter}>
          {showTypewriter && (
            <Typewriter  
              options={{
                strings: ['H E A L T H'],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                pauseFor: 99999,
                cursor: '',
              }}
            />
          )}
        </div>
      </div>

      <div className="text-l font-thin text-center text-blue-600 mb-4">
        <div style={styles.typewriter}>
          {showTypewriter && (
            <Typewriter  
              options={{
                strings: ['Healthy Eating & Lifestyle Transformation Hub Chatbot'],
                autoStart: true,
                loop: false,
                deleteSpeed: Infinity,
                pauseFor: 99999,
                cursor: '',
              }}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col justify-end ">
        <div style={styles.container}>
          <img  
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTTKpdIqFPiB6635S_ZYYO6zm0pzqsxRaVo9A&s"
            alt="Logo"
            style={styles.logo}
          />
          <img  
            src={`${process.env.PUBLIC_URL}/healthChat.jpg`}
            alt="HealthChat Logo"
            style={styles.logo}
          />

        </div>
        <div className="flex justify-center get-started-container">
          <Ripples color="rgba(255, 255, 255, 0.3)">
            
            <FloatingPanel>
              <button  
                onClick={handleClick}
                className={`px-6 py-3 font-bold text-white rounded-lg shadow-md glowing-button shadow-blue-500/15 transition-opacity duration-1000 ${
                  isFadingOut ? 'opacity-0' : 'opacity-100'
                }  glowing-button`}
              >
                Get Started
              </button>
            </FloatingPanel>
          </Ripples>
        </div>
      </div>
    </div>
    </>
  );
};

export default Home;