import React, { useEffect, useState } from 'react';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import Typewriter from 'typewriter-effect';
import Ripples from 'react-ripples';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setCurrentPage } from '../store/Store';

const Survey = ({ setIsAllowedBackHome }) => {
  const [loggedInName, setLoggedInName] = useState(null);
  const [nickname, setNickname] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const storedName = localStorage.getItem('loggedInName');
    if (storedName) {
      setLoggedInName(storedName);
    }
    setIsAllowedBackHome(false);

    dispatch(setCurrentPage('/survey'));
  }, [dispatch, setIsAllowedBackHome]);

  const handleLoginWithGmail = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    window.location.href = `${baseUrl}/api/oauth/login/google`;
  };

  const handleClick = () => {
    navigate('/');
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow only alphanumeric characters and spaces  
    if (/^[a-zA-Z0-9 ]*$/.test(value)) {
      setNickname(value);
    }
  };

  const answerSurvey = (questionNum, answerString) => {
    console.log(`Question ${questionNum}: ${answerString}`);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    answerSurvey(1, nickname);
  };

  return (
    <>
      <div className="flex justify-center mt-4">
        <div className="text-2xl font-thin text-center text-blue-600">
          <Typewriter  
            options={{
              strings: ['healthChat Survey'],
              autoStart: true,
              loop: false,
              deleteSpeed: Infinity,
              cursor: '',
            }}
          />
        </div>
      </div>

      {loggedInName ? (
        <>
          <div className="flex justify-center mt-4">
            <img src='thumbnail/robot-1_64.jpg' alt='healthChat' 
            className="w-16 h-16 rounded-full object-cover" />
            <div className="text-center text-xl font-thin text-blue-600">
            Let's start the survey, {loggedInName}. 
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <div className="text-center text-xl font-thin text-blue-600">

              <Typewriter  
                options={{
                  strings: [`What's your nickname?`],
                  autoStart: true,
                  loop: false,
                  deleteSpeed: Infinity,
                  cursor: '',
                }}
              />
              
            </div>
          </div>
          <div className="flex justify-center mt-4">
            <form onSubmit={handleSubmit} className="flex flex-col items-center mt-2">
              <input  
                type="text"
                value={nickname}
                onChange={handleInputChange}
                placeholder="Enter your nickname"
                className="border p-2 rounded"
                maxLength={15}
              />
              <button  
                type="submit"
                className="mt-2 px-4 py-2 bg-blue-500 text-white rounded"
              >
                Next  
              </button>
            </form>
          </div>
        </>
      ) : (
        <>
          <div style={{ textAlign: 'center' }}>
            Coming Soon  
          </div>
          {/* <div className="flex justify-center mt-4">
            <Ripples color="rgba(255, 255, 255, 0.3)">
              <button  
                onClick={handleLoginWithGmail}
                className="px-6 py-3 mb-4 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-red-500 to-red-700 shadow-red-500/15"
              >
                <FontAwesomeIcon icon={faGoogle} className="mr-2" /> Login Gmail
              </button>
            </Ripples>
          </div> */}
          {/* <div className="flex justify-center mt-4">
            <Ripples color="rgba(255, 255, 255, 0.3)">
              <button  
                onClick={handleClick}
                className="px-6 py-3 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-blue-500 to-blue-700 shadow-blue-500/15"
              >
                {t('back.home')}
              </button>
            </Ripples>
          </div> */}
        </>
      )}
    </>
  );
};

export default Survey;