import React, { useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';
import Ripples from 'react-ripples';

const Translate = ({ t, ...rest }) => {
  const navigate = useNavigate();

  // Use the rest parameter to access additional props
  const { setIsAllowedBackHome } = rest;

  useEffect(() => {
    if (setIsAllowedBackHome) {
      setIsAllowedBackHome(false);
    }
  }, [setIsAllowedBackHome]);

  const handleLanguageChange = (lng) => {
    i18n.changeLanguage(lng);
    navigate('/'); // Navigate back to home after language change
  };

  return (
    <>
      <div className="flex justify-center mt-4">
        <div className="text-2xl font-thin text-center">
          {/* Display language-specific text */}
          {t('choose-en')}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <Ripples color="rgba(255, 255, 255, 0.3)">
          {/* Conditionally render the language change button */}
          {i18n.language === 'en' ? (
            <button
              onClick={() => handleLanguageChange('ms')}
              className="px-6 py-3 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-blue-500 to-blue-700 shadow-blue-500/15"
            >
              {t('choose-ms')}
            </button>
          ) : (
            <button
              onClick={() => handleLanguageChange('en')}
              className="px-6 py-3 font-bold text-white rounded-lg shadow-md bg-gradient-to-r from-blue-500 to-blue-700 shadow-blue-500/15"
            >
              {t('choose-en')}
            </button>
          )}
        </Ripples>
      </div>
    </>
  );
};

export default withTranslation()(Translate);