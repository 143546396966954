class AbstractObjectModel {
    constructor() {
      this.createdDate = new Date();
      this.updatedDate = new Date();
      this.createdBy = null;
      this.lastUpdatedBy = null;
      this.creatorAgent = 'React App';
      this.updaterAgent = 'React App';
      this.creatorBackendVersion = null;
      this.updaterBackendVersion = null;
      this.isDeleted = false;
    }
  }
  
  export default AbstractObjectModel;
  