import React from 'react';

const Privacy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      {/* Your privacy policy content here */}
    </div>
  );
};

export default Privacy;