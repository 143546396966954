
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { clearStore } from '../store/Store'; // Import action to clear Redux store

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    // Clear Redux store  
    dispatch(clearStore());

    // Clear local storage  
    localStorage.clear();

    // Navigate to home  
    navigate('/');
  };

  return logout;
};
export const formatTime = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return 'just now';
    }
  
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const minutesStr = minutes < 10 ? '0' + minutes : minutes;
    return `${hours}:${minutesStr} ${ampm}`;
  };

export const getArrayByStateOfIntroduction = (stateOfIntroduction) => {
    switch(stateOfIntroduction){
        case '10': 
            return {
                text: '🙋‍♀️ Hi, Nice to meet you, I am healthChat!',
                user: {
                id: 'healthChat',
                name: 'healthChat',
                avatar: 'chat/01-bot-front.svg'
                },
                timestamp: new Date()
            };
        case '20a':
            return {
                text: 'Tell me more',
                user: {
                  id: 'user01',
                  name: 'You'
                },
                timestamp: new Date()
              };
        case '20b':
            return {
              text: "👍 Ok..I'm healthChat.",
              user: {
                id: 'healthChat',
                name: 'healthChat',
                avatar: 'chat/01-bot-front.svg'
              },
              timestamp: new Date()
            };
        case '20c':
            return {
                text: "Healthy Eating and Lifestyle Transformation Hub Chatbot is my full name. I am your personal health assistant specialised in Fat Loss.",
                user: {
                  id: 'healthChat',
                  name: 'healthChat',
                avatar: 'chat/01-bot-front.svg'
                },
                timestamp: new Date()
            };
        case '20d':
            return {
                text: "I would like to invite you to participate in our survey!",
                user: {
                  id: 'healthChat',
                  name: 'healthChat',
                avatar: 'chat/01-bot-front.svg'
                },
                timestamp: new Date()
            };
    }
    return [];
}