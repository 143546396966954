import AbstractObjectModel from './AbstractObjectModel';

class SessionInfo extends AbstractObjectModel {
  constructor() {
    super(); // Call the parent class constructor

    this._id = null; // To store the MongoDB generated _id  
    this.sessionId = ''; // Initialize as an empty string  
    this.ipAddress = ''; // Initialize as an empty string  
  }
}

export default SessionInfo;