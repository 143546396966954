
import React from 'react';
import { Provider, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';
import Home from './component/Home';
import Survey from './component/Survey';
import Start from './component/start/Start';
import Privacy from './component/start/Privacy';
import Terms from './component/start/Terms';
import './input.css';
import Debug from './component/tell-me-more/Debug';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './translate/en.json';
import msTranslation from './translate/ms.json';
import Translate from './translate/Translate';
import TestChat from './component/chat/TestChat';
import Store from './store/Store';
import { useLogout } from './util/Util';

const App = () => {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [avatarAnchorEl, setAvatarAnchorEl] = React.useState(null);
  const [isAllowedBackHome, setIsAllowedBackHome] = React.useState(false);

  const user = useSelector(state => state.app.user); // Get user info from Redux  
  const isLoggedIn = !!user.email; // Derive isLoggedIn from user email presence  
  const avatarUrl = isLoggedIn ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTbunjG0clqdBDZUtPkuGFbXuL1csW0EAQ_BQ&s' : null;
  
  const navigate = useNavigate();
  const logout = useLogout();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarMenu = (event) => {
    setAvatarAnchorEl(event.currentTarget);
  };

  const handleTitleClick = () => {
    if (isAllowedBackHome) {
      navigate('/');
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAvatarAnchorEl(null);
  };

  const handleNavigation = (path) => {
    navigate(path);
    handleClose();
  };


  return (
    <div>
      <AppBar position="static">
        {location.pathname !== '/start' && (
          <Toolbar>
            <IconButton  
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleMenu}
            >
              <MenuIcon />
            </IconButton>
            <Typography style={{ flexGrow: 1 }} className="font-thin" onClick={handleTitleClick}>
              healthChat  
            </Typography>
            <IconButton  
              edge="end"
              color="inherit"
              aria-label={isLoggedIn ? 'user-avatar' : 'settings'}
              onClick={handleAvatarMenu}
            >
              {isLoggedIn && avatarUrl ? (
                <img src={avatarUrl} alt="User Avatar" style={{ width: 30, height: 30, borderRadius: '50%' }} />
              ) : (
                <SettingsIcon />
              )}
            </IconButton>
            <Menu  
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handleNavigation('/')}>Home</MenuItem> 
              <MenuItem onClick={() => handleNavigation('/survey')}>Survey</MenuItem>
              <MenuItem onClick={() => handleNavigation('/privacy')}>Privacy</MenuItem>
              <MenuItem onClick={() => handleNavigation('/terms')}>Terms</MenuItem>
              <MenuItem onClick={() => handleNavigation('/testchat')}>Test Chat</MenuItem>
            </Menu>
            {isLoggedIn && (
              <Menu  
                anchorEl={avatarAnchorEl}
                open={Boolean(avatarAnchorEl)}
                onClose={handleClose}
              >
                {/* <MenuItem onClick={() => handleNavigation('/translate')}>Language</MenuItem> */}
                <MenuItem onClick={logout}>Logout</MenuItem>
              </Menu>
            )}
          </Toolbar>

        )}
      </AppBar>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/start" element={<Start />} />
        <Route path="/survey" element={<Survey setIsAllowedBackHome={setIsAllowedBackHome} />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms setIsAllowedBackHome={setIsAllowedBackHome} />} />
        <Route path="/translate" element={<Translate />} />
        <Route path="/debug" element={<Debug />} />
        <Route path="/testchat" element={<TestChat setIsAllowedBackHome={setIsAllowedBackHome} />} />
      </Routes>
    </div>
  );
};

const AppWrapper = () => (
  <Provider store={Store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

const resources = {
  en: {
    translation: enTranslation,
  },
  ms: {
    translation: msTranslation,
  },
};

i18n  
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default AppWrapper;