import React, { useState, useEffect } from 'react';

const FloatingPanel = ({ children }) => {
  const [isKeyboardOpen, setIsKeyboardOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsKeyboardOpen(window.innerHeight < (window.screen.availHeight - 100)); // Adjust threshold as needed
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div
      className="floating-panel"   

      style={{
        position: 'fixed',
        bottom: isKeyboardOpen ? 50 : 20, // Adjust bottom position based on keyboard
        left: 0,
        right: 0,
        zIndex: isKeyboardOpen ? 100 : 1, // Ensure panel is above keyboard
        display: 'flex',
        justifyContent: 'center', // Center the child element
      }}
    >
      {children}
    </div>
  );
};

export default FloatingPanel;